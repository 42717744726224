import { MatconformdialogComponent } from './components/matconformdialog/matconformdialog.component';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ControlsModule } from './Modules/controls/controls.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DynamictabsDirective } from './LandingPageComponents/dynamictabs.directive';
import { FullComponent } from './LandingPageComponents/full/full.component';
import { ComponentsModule } from './Modules/components/components.module';
import { TabsComponent } from './LandingPageComponents/tabs/tabs.component';
import { AppSidebarComponent } from './LandingPageComponents/sidebar/sidebar.component';
import { AppHeaderComponent } from './LandingPageComponents/header/header.component';
import { MenuListItemComponent } from 'src/app/LandingPageComponents/menu-list-item/menu-list-item.component';
import { LogInComponent } from './log-in/log-in.component';
import { ErrorInterceptor } from './Services/httpServices/Interceptors/error.interceptor';
import { JwtInterceptor } from './Services/httpServices/Interceptors/jwt.interceptor';
import { AppErrorHandler } from './Services/httpServices/ErrorHandler/app-error-handler';
import { NgxImageCompressService } from 'ngx-image-compress';
import { WebcamModule } from 'ngx-webcam';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxPieChartModule } from 'devextreme-angular';
import { DashboardComponent } from './LandingPageComponents/dashboard/dashboard.component';
import { DxChartModule } from 'devextreme-angular';
import { DxPolarChartModule, DxSelectBoxModule,  DxScrollViewModule,
  DxSortableModule, } from 'devextreme-angular';


import { DevxkanbanboardComponent } from './components/ITS/DevXKanban/devxkanbanboard/devxkanbanboard.component';


@NgModule({
  declarations: [AppComponent, DynamictabsDirective,
   FullComponent,
   TabsComponent,
  AppSidebarComponent,
  AppHeaderComponent,
  MenuListItemComponent,
  LogInComponent,
  MatconformdialogComponent,
  DashboardComponent,
  
  DevxkanbanboardComponent,
  
  
  ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, 
    BrowserAnimationsModule,ControlsModule,HttpClientModule ,ComponentsModule,BrowserTransferStateModule,FlexLayoutModule,
    FormsModule,
    
    WebcamModule,
    DxPieChartModule,DxChartModule,DxPolarChartModule,DxSelectBoxModule, 
    DxScrollViewModule,
    DxSortableModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [NgxImageCompressService,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler},

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
