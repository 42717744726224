import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/Modules/user';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { ApprovalformfilterComponent } from '../approvalformfilter/approvalformfilter.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApprovalformPostingComponent } from '../approvalform-posting/approvalform-posting.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';


@Component({
  selector: 'app-approvalformlist',
  templateUrl: './approvalformlist.component.html',
  styleUrls: ['./approvalformlist.component.scss'],
})
export class ApprovalformlistComponent implements OnInit {

  showSpinner:any;
  //data:any;
  Salesmanlist:any;
  UserID:any;
  cid:any=101;
  LedgerID:any=0;
  today = new Date();
 priorDate = new Date(new Date().setDate(this.today.getDate() - 30));
  fromdate:any= this.priorDate;
  todate:any= new Date();
  dataSource: any;
  dataSource1:any;
  flag:any;
  qtnstatus="0"
  searchQuery: string = '';
  curUser: User= JSON.parse(sessionStorage.getItem('currentUser'));
  currentPage: number = 1;
  itemsPerPage: number = 17; // Number of items per page
  sortOrder: string = ''; // Ascending or Descending
  displayedColumns =['VouNo','VouDate','Description','MenuName','ApprovedStatus','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service:HttpserviceService,private datePipe: DatePipe,private generic: GenericService,public dialog: MatDialog) 
  {
    
  }

  // onPageChange(pageNumber: number) {
  //   this.currentPage = pageNumber;
  // }
  // getPages(): number[] {
  //   // const pageCount = Math.ceil(this.dataSource.length / this.itemsPerPage);
  //   // return Array(pageCount).fill(0).map((_, index) => index + 1);
  //   const pageCount = Math.ceil(this.dataSource.length / this.itemsPerPage);
  // const currentPageIndex = this.currentPage - 1;

  // // Calculate the start and end index for the displayed page numbers
  // let startPageIndex = Math.max(0, currentPageIndex - 2);
  // let endPageIndex = Math.min(pageCount - 1, currentPageIndex + 2);

  // // Ensure there are always 5 page numbers displayed
  // if (endPageIndex - startPageIndex < 4) {
  //   if (startPageIndex === 0) {
  //     endPageIndex = Math.min(pageCount - 1, startPageIndex + 4);
  //   } else if (endPageIndex === pageCount - 1) {
  //     startPageIndex = Math.max(0, endPageIndex - 4);
  //   }
  // }

  // return Array.from({ length: endPageIndex - startPageIndex + 1 }, (_, index) => startPageIndex + index + 1);
  // }

  ngOnInit() 
  {
    this. LoadData()
  }
  // sortColumn(columnName: string) {
  //   // Toggle sorting order between Ascending and Descending
  //   this.sortOrder = (this.sortOrder === 'asc') ? 'desc' : 'asc';

  //   // Sort the filteredData based on the column
  //   this.dataSource.sort((a, b) => {
  //     if (a[columnName] < b[columnName]) {
  //       return (this.sortOrder === 'asc') ? -1 : 1;
  //     } else if (a[columnName] > b[columnName]) {
  //       return (this.sortOrder === 'asc') ? 1 : -1;
  //     } else {
  //       return 0;
  //     }
  //   });
  // }

  LoadData()
  {

    this.showSpinner=true;
    const params = {
      cid: this.cid,
      // salesmanid: this.curUser.salesmanid,
      UserID:this.curUser.userid,
      FromDate:this.datePipe.transform(this.fromdate,"MM-dd-yyyy")  ,
      ToDate:this.datePipe.transform( this.todate,"MM-dd-yyyy"),
      Condition:this.qtnstatus,
      Flag:'Get'
    
    };   
    console.log('params',params)
    this.service.httpPost('/ApprovalForm/GetApprovalForm?' ,params)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("mm",result.respdata)
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)  
     
        this.dataSource = result.respdata.Table;  
        this.dataSource= new MatTableDataSource(result.respdata.Table);  
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource1 = result.respdata.Table; 
    
         
    
       
    });
  }
 
  onUpdate(e)
  {
    console.log("evebt",e)

    console.log("test",e)
    console.log("VouNo",e.RuleID)
    // let param={
    //   VouNo:e.VouNo,
    //   Description:e.Description,
    //    VouDate:e.VouDate,
    //   ApprovedStatus:e.ApprovedStatus,
    //   Amount:e.Amount,
    //   MenuID:e.MenuID,
    //   RevNo:e.RevNo,
    //   ApproveBy:e.ApprovedBy,
    //   LedgerID:e.LedgerID,
    //   RuleID:e.RuleID
    //   // LedgerID:this.LedgerID,
    //   // OtherForm:true
     
    // }

    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        VouNo:e.VouNo,
        Description:e.Description,
         VouDate:e.VouDate,
        ApprovedStatus:e.ApprovedStatus,
        Amount:e.Amount,
        MenuID:e.MenuID,
        RevNo:e.RevNo,
        ApproveBy:e.ApprovedBy,
        LedgerID:e.LedgerID,
        RuleID:e.RuleID,
        CID:this.cid
      };
      
    if (e.ApprovedStatus!=='Pending')
    {
      //this.router.navigate(['ApprovalFormPosting'], {state: {data:param}})


      const dialogRef = this.dialog.open(ApprovalformPostingComponent, dialogConfig);
      // const dialogRef = this.dialog.open(ApprovalformPostingComponent, {
      //   data:param
      //   // width: '300px', // Set the width of the dialog
      //   // position: { right: '0' } // Set the position to right side
      // });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed',result.Status);
        //this.qtnstatus=result.Status
        this.LoadData()
      });

    }

  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(ApprovalformfilterComponent, {
      width: '30%', // Set the width of the dialog
      height:'75%',
      position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        qtnstatus:this.qtnstatus,
        fromdate:this.fromdate,
        todate:this.todate
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
        this.qtnstatus=result.Status
        this.cid=result.CID
        this.fromdate=result.FromDate
        this.todate=result.ToDate
        this.LoadData()
      }
     
    });
  }
  
  onSearch() {
    console.log("test")
    // this.dataSource = this.dataSource1.filter(item =>
    //   item.toLowerCase().includes(this.searchQuery.toLowerCase())
    // );
   // this.dataSource= result.respdata.Table);  
    this.dataSource =new MatTableDataSource( this.dataSource1.filter(item => {
      const itemString = `${item.VouNo.toLowerCase()} || ${item.Description.toLowerCase()} || ${item.MenuName.toLowerCase()} || ${item.ApprovedStatus.toLowerCase()}`;
      return itemString.includes(this.searchQuery.toLowerCase());
      
    }));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.currentPage=1
  }
  exportAsExcel()
    {
      // , {header:['name', 'surname']}
      this.generic.excelExport(this.dataSource1, 'Effort','EffortList From ' + this.datePipe.transform(this.fromdate,"dd-MM-yyyy")+' to '+this.datePipe.transform(this.todate,"dd-MM-yyyy"));
    }

}
