import { HrpoliciesviewComponent } from './../components/HR/HRPolicy/hrpoliciesview/hrpoliciesview.component';

//import { AuthconfiglistComponent } from './../components/WorkFlow/AuthConfig/authconfiglist/authconfiglist.component';
import { TicketlistComponent } from './../components/ITS/Ticket/ticketlist/ticketlist.component';
import { DatewisereportComponent } from './../components/ETS/DateWiseReport/datewisereport/datewisereport.component';
import { MerchantlistComponent } from './../components/WorkFlow/Client/merchantlist/merchantlist.component';
import { AssementgroupComponent } from './../components/WorkFlow/AssementGroup/assementgroup/assementgroup.component';
import { TimezonelistComponent } from './../components/WorkFlow/TimeZone/timezonelist/timezonelist.component';
import { QuestionlistComponent } from './../components/WorkFlow/Question/questionlist/questionlist.component';
import { QuestionComponent } from './../components/WorkFlow/Question/question/question.component';
import { EmailconfigurationComponent } from './../components/WorkFlow/EmailConfig/emailconfiguration/emailconfiguration.component';
import { ProductconfiglistComponent } from './../components/WorkFlow/Product/productconfiglist/productconfiglist.component';
import { HrconfigurationComponent } from './../components/WorkFlow/HRConfig/hrconfiguration/hrconfiguration.component';

import { LeavetypemasterviewComponent } from './../components/WorkFlow/LeaveTypeMaster/leavetypemasterview/leavetypemasterview.component';
import { EffortapprovalComponent } from './../components/Project/EffortApproval/effortapproval.component';
import { DocumentcontrollistComponent } from './../components/Employee/Documents/documentcontrollist/documentcontrollist.component';
import { ProjectallocationbyweekListComponent } from './../components/Project/ProjectAllocation/projectallocationbyweek-list/projectallocationbyweek-list.component';
import { EmpRegListComponent } from './../components/HR/EmployeeRegistration/emp-reg-list/emp-reg-list.component';
import { ProjecteffortComponent } from './../components/Project/projecteffort/projecteffort.component';
import { EmployeeHierarchyComponent } from './../components/Project/EmpHierarchy/employee-hierarchy/employee-hierarchy.component';
import { EmployeeHierarchyListComponent } from './../components/Project/EmpHierarchy/employee-hierarchy-list/employee-hierarchy-list.component';
import { ProjectconfigurationListComponent } from './../components/WorkFlow/ProjectMaster/projectconfiguration-list/projectconfiguration-list.component';
import { GroupListComponent } from './../components/Admin/group/group-list/group-list.component';
import { UserListComponent } from './../components/Admin/users/user-list/user-list.component';
import { EmpleaverequestComponent } from './../components/HR/LeaveApproval/empleaverequest/empleaverequest.component';
import { LeaveApprovalComponent } from './../components/HR/LeaveApproval/leave-approval/leave-approval.component';
import { LeaverequestComponent } from './../components/HR/LeaveApproval/leaverequest/leaverequest.component';
import { TicketreportComponent } from './../components/ETS/ticketreport/ticketreport.component';
import { MonthlyreportComponent } from './../components/ETS/monthlyreport/monthlyreport.component';
import { CustomsreportComponent } from '../../app/components/ETS/CustomsReport/customsreport.component';
import { LedgerstatementComponent } from './../components/Employee/LedgerStatement/ledgerstatement.component';
import { AdvancerequestComponent } from './../components/Employee/AdvanceRequest/advancerequest/advancerequest.component';
import { TimesheetComponent } from './../components/HR/TimeSheet/timesheet/timesheet.component';
import { HrpoliciesComponent } from './../components/HR/HRPolicy/hrpolicies/hrpolicies.component';

import { AttendancereportComponent } from './../components/HR/AttendanceReport/attendancereport/attendancereport.component';
import { ExpensepostinglistComponent } from './../components/Employee/Expenseposting/expensepostinglist/expensepostinglist.component';
import { TimepunchreportlistComponent } from './../components/HR/timepuchreport/timepunchreportlist/timepunchreportlist.component';
import { Injectable } from '@angular/core';
import { EmpAttReportViewComponent } from '../../app/components/Employee/EmpAttReportview/emp-att-report-view/emp-att-report-view.component';
import { GoalslistComponent } from '../components/HR/Goals/goalslist/goalslist.component';
import { TraininglistComponent } from '../components/HR/Training/traininglist/traininglist.component';
import { EmpleaverptComponent } from '../../app/components/HR/EmpLeaveReport/empleaverpt.component';
import { E } from '@angular/cdk/keycodes';
import { LeavetypemasterComponent } from '../components/WorkFlow/LeaveTypeMaster/leavetypemaster/leavetypemaster.component';
import { LeaveManagementComponent } from '../components/HR/LeaveManagement/leave-management/leave-management.component';
// import { TimepunchreportComponent } from '../../app/components/timepuchreport/timepunchreport/timepunchreport.component';
// import { AttendancereportComponent } from '../components/AttendanceReport/attendancereport/attendancereport.component';

// import { DatePipe } from '@angular/common';
// import { DemoMaterialModule } from '../demo-material-module';
import{ EntityconfigComponent } from '../../app/components/WorkFlow/Entity/entityconfig/entityconfig.component';
import { EstimationweeklyreportComponent } from '../components/Project/estimationweeklyreport/estimationweeklyreport.component';
import { AuthMasterComponent } from '../components/HR/auth-master/auth-master.component';
import { TypeofissuelistComponent } from '../components/WorkFlow/TypeOfIssue/typeofissuelist/typeofissuelist.component';
import { BranchlistComponent } from '../components/WorkFlow/Branch/branchlist/branchlist.component';
import { ItempricelistComponent } from '../components/ERP/Inventory/inventory/itempricelist/itempricelist.component';
import { NeworderlistComponent } from '../components/ERP/Sales/neworderlist/neworderlist.component';
import { StatuslistComponent } from '../components/WorkFlow/Status/statuslist/statuslist.component';
import { CategorylistComponent } from '../components/WorkFlow/Category/categorylist/categorylist.component';
import { LeavegrouplistComponent } from '../components/WorkFlow/LeaveGroup/leavegrouplist/leavegrouplist.component';
import { ConsolidateEffortComponent } from '../components/ETS/Consolidatereport/consolidate-effort/consolidate-effort.component';
import { EffortauthorizeComponent } from '../components/ETS/EffortAuthorize/effortauthorize/effortauthorize.component';
import { AttendanceComponent } from '../components/Project/attendance/attendance.component';
import { TaskbyComponent } from '../components/ITS/CustomsReport/taskby/taskby.component';
import { ITSHomepageComponent } from '../components/ITS/itshomepage/itshomepage.component';
import { ProjectAllocationByDayComponent } from '../components/Project/project-allocation-by-day/project-allocation-by-day.component';
import { ResourceallocationbydayComponent } from '../components/Project/resourceallocationbyday/resourceallocationbyday.component';
import { EmpTimesheetComponent } from '../components/ETS/EmpTimesheet/emp-timesheet/emp-timesheet.component';
import { SampleComponent } from '../components/ETS/Sample/sample/sample.component';
import { ItsuserhomepageComponent } from '../components/ITS/Ticket/itsuserhomepage/itsuserhomepage.component';
import { EmpattendanceviewComponent } from '../components/Employee/AttendanceReport/empattendanceview/empattendanceview.component';
import { CustomizeditemrptComponent } from '../components/ERP/customizeditemrpt/customizeditemrpt.component';
import { DocumentreqlistComponent } from './../components/Employee/DocumentRequest/documentreqlist/documentreqlist.component';
import { DocumentreqviewComponent } from '../components/Employee/DocumentRequest/documentreqview/documentreqview.component';
import { EmployeeOnBoardlistComponent } from '../components/Employee/EmployeeOnBoard/employee-on-boardlist/employee-on-boardlist.component';
import { EmpdocreqlistComponent } from '../components/Employee/DocumentRequest/empdocreqlist/empdocreqlist.component';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { DatecontrolComponent } from '../components/HR/DateControl/datecontrol/datecontrol.component';
import { ProjectcostComponent } from './../components/WorkFlow/ProjectCost/projectcost/projectcost.component';
import { EffortcostreportComponent } from '../components/ETS/EffortCostReport/effortcostreport/effortcostreport.component';
import { HelpdesktaskbyComponent } from '../components/ITS/Helpdesk/helpdesktaskby/helpdesktaskby.component';
import { TimepunchComponent } from '../components/HR/Timepunch/timepunch/timepunch.component';
import { TimepunchotreportComponent } from '../components/HR/timepuchreport/timepunchotreportlist/timepunchotreport/timepunchotreport.component';
import { TimepunchleavepostingComponent } from '../components/HR/Timepunchleaveposting/timepunchleaveposting/timepunchleaveposting.component';
import { MydocumentComponent } from '../components/Employee/Documents/mydocument/mydocument.component';
import { AuthorizegroupComponent } from '../components/HR/authorizegroup/authorizegroup.component';
import { KanbanboardComponent } from '../components/ITS/kanbanboard/kanbanboard.component';
import { NewleavedirectapprovalComponent } from '../components/HR/LeaveApproval/newleavedirectapproval/newleavedirectapproval.component';
import { HrgoalslistComponent } from '../components/HR/Goals/hrgoalslist/hrgoalslist.component';
import { NewleaverequestComponent } from '../components/HR/LeaveApproval/newleaverequest/newleaverequest.component';
import { LeavetypeconfigurationComponent } from '../components/HR/leavetypeconfiguration/leavetypeconfiguration.component';
import { NestedgridComponent } from '../components/WorkFlow/nestedgrid/nestedgrid.component';
import { TicketemailconfigexcelimportComponent } from '../components/WorkFlow/ticketemailconfigexcelimport/ticketemailconfigexcelimport.component';
import { TicketemailrulecreationComponent } from '../components/WorkFlow/ticketemailrulecreation/ticketemailrulecreation.component';
import { EffortauthconfigComponent } from '../components/WorkFlow/EffortAuthorizer/effortauthconfig/effortauthconfig.component';
import { ResourceallocationbyweekComponent } from '../components/Project/resourceallocationbyweek/resourceallocationbyweek.component';
import { AspentaskbyreportComponent } from '../components/ITS/CustomsReport/aspentaskbyreport/aspentaskbyreport.component';
import { AduitviewComponent } from '../components/ERP/auditview/aduitview.component';
import { KanbanboardconfigComponent } from '../components/ITS/kanbanboardconfig/kanbanboardconfig.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConsolidatetimepunchrptlistComponent } from '../components/HR/timepuchreport/consolidatetimepunchrptlist/consolidatetimepunchrptlist.component';
import { ShifttimepunchreportComponent } from '../components/HR/ShiftManagement/shifttimepunchreport/shifttimepunchreport.component';
import { TickettreeviewComponent } from '../components/ITS/tickettreeview/tickettreeview.component';
import { ApprovalformlistComponent } from '../components/ERP/ApprovalForm/approvalformlist/approvalformlist.component';
import { DependencyrptComponent } from '../components/ITS/dependencyrpt/dependencyrpt.component';
import { TicketprefixconfigComponent } from '../components/WorkFlow/ticketprefixconfig/ticketprefixconfig.component';
import { TicketlinklistComponent } from '../components/WorkFlow/TicketLink/ticketlinklist/ticketlinklist.component';
import { PayslipComponent } from '../components/HR/payslip/payslip.component';
import { EmpsalaryhikeComponent } from '../components/HR/SalaryHike/empsalaryhike/empsalaryhike.component';
import { PayslipreportComponent } from '../components/HR/Payslip Report/payslipreport/payslipreport.component';
import { EmpaccountsComponent } from '../components/HR/EmpAccounts/empaccounts/empaccounts.component';
import { PstemplateComponent } from '../components/HR/PSTemplate/pstemplate/pstemplate.component';
import { EmppayslippaymentComponent } from '../components/HR/EmpPayslipPayment/emppayslippayment/emppayslippayment.component';
import { PstemplatelistComponent } from '../components/HR/PSTemplate/pstemplatelist/pstemplatelist.component';
import { EmpcategoryComponent } from '../components/HR/EmpCategory/empcategory/empcategory.component';
import { DevxgridComponent } from '../components/Admin/devxgrid/devxgrid.component';
import { DevxkanbanboardComponent } from '../components/ITS/DevXKanban/devxkanbanboard/devxkanbanboard.component';
import { EosreportComponent } from '../components/HR/EOS/eosreport/eosreport.component';
import { LeavesalaryencashreportComponent } from '../components/HR/LeaveSalary_EnCash/leavesalaryencashreport/leavesalaryencashreport.component';
import { LicensecreatemainComponent } from '../components/Admin/License/licensecreatemain/licensecreatemain.component';
import { LicensereportComponent } from '../components/Admin/License/licensereport/licensereport.component';


@Injectable({
  providedIn: 'root'
})
export class MenuidmappingService {

  menuIDMapping:any = [  
    {menuid: 'STS_120', component: TicketemailconfigexcelimportComponent},
    {menuid: 'STS_121', component: TicketemailrulecreationComponent},
    {menuid: 'STS_96', component: EmpAttReportViewComponent},
    {menuid: 'STS_54', component: TimepunchreportlistComponent},
    {menuid: 'STS_20', component: ExpensepostinglistComponent},
    {menuid: 'STS_15', component: AttendancereportComponent},
    {menuid: 'STS_86', component: GoalslistComponent},
    {menuid: 'STS_95', component: TraininglistComponent},
    //{menuid: 'STS_13', component: HrpoliciesComponent},
    {menuid: 'STS_13', component: HrpoliciesviewComponent},
    {menuid: 'STS_24', component: TimesheetComponent},
    {menuid: 'STS_77', component: EmpleaverptComponent},
    {menuid: 'STS_18', component: AdvancerequestComponent},
    {menuid: 'STS_23', component: LedgerstatementComponent},
    {menuid: 'STS_29', component: CustomsreportComponent},
    {menuid: 'STS_27', component: MonthlyreportComponent},
    {menuid: 'STS_31', component: TicketreportComponent},
    {menuid: 'STS_11', component: NewleaverequestComponent},
    {menuid: 'STS_21', component: NewleavedirectapprovalComponent},
    {menuid: 'STS_94', component: EmpleaverequestComponent},
    {menuid: 'ERP_103', component: UserListComponent},//UserListComponent
    {menuid: 'ERP_104', component: GroupListComponent},
    {menuid: 'ERP_128', component: ProjectconfigurationListComponent},
    //{menuid: 'ERP_128', component: AuthconfiglistComponent},
    {menuid: 'STS_90', component: EmployeeHierarchyListComponent},
    //{menuid: 'STS_92', component: ProjecteffortComponent},
    {menuid: 'STS_92', component: AttendanceComponent},
    {menuid: 'STS_8', component: EmpRegListComponent},
    {menuid: 'STS_63', component: ProjectallocationbyweekListComponent},
    {menuid: 'STS_6', component: DocumentcontrollistComponent},
    {menuid: 'STS_91', component: EffortapprovalComponent},
    {menuid: 'STS_5', component: LeavetypemasterComponent},
    {menuid: 'STS_22', component: LeaveManagementComponent},
    {menuid: 'STS_70', component: EntityconfigComponent},
    {menuid: 'STS_97', component: HrconfigurationComponent},
   {menuid: 'STS_44', component: ProductconfiglistComponent},
    // {menuid: 'STS_44', component: WebcameraComponent},
    {menuid: 'STS_62', component: EstimationweeklyreportComponent},
    {menuid: 'STS_46', component: EmailconfigurationComponent},
    {menuid: 'STS_98', component: QuestionlistComponent},
    {menuid: 'STS_12', component: AuthMasterComponent},
    {menuid: 'STS_74', component: TypeofissuelistComponent},
    {menuid: 'STS_72', component: BranchlistComponent},
    {menuid: 'STS_9', component: TimezonelistComponent},
    {menuid: 'ERP_291', component: ItempricelistComponent},
   // {menuid: 'ERP_291', component: DatecontrolComponent},
    {menuid: 'ERP_156', component:NeworderlistComponent },
    {menuid: 'STS_100', component:AssementgroupComponent },
    {menuid: 'STS_34', component:StatuslistComponent },
    {menuid: 'STS_35', component:CategorylistComponent },
    {menuid: 'STS_4', component:LeavegrouplistComponent },
    {menuid: 'STS_45', component:MerchantlistComponent },
    {menuid: 'STS_30', component:DatewisereportComponent },
    {menuid: 'STS_101', component:ConsolidateEffortComponent },
    {menuid: 'STS_26', component:EffortauthorizeComponent },
    {menuid: 'STS_203', component: ItsuserhomepageComponent},
    {menuid: 'STS_41', component: TaskbyComponent},
    {menuid: 'STS_99', component: ITSHomepageComponent},
    {menuid: 'STS_93', component: ProjectAllocationByDayComponent},
    {menuid: 'STS_102', component: ResourceallocationbydayComponent},
    //{menuid: 'STS_103', component: EmpTimesheetComponent},
    {menuid: 'STS_103', component: EmpTimesheetComponent},
    {menuid: 'STS_105', component: EmpattendanceviewComponent},
    {menuid: 'ERP_500', component: CustomizeditemrptComponent},
    {menuid: 'STS_106', component: DocumentreqlistComponent},
    {menuid: 'STS_206', component: EmployeeOnBoardlistComponent},
    {menuid: 'STS_107', component: EmpdocreqlistComponent},
    {menuid: 'STS_108', component: ProjectcostComponent},
    {menuid: 'STS_109', component: EffortcostreportComponent},
    {menuid: 'STS_208', component: HelpdesktaskbyComponent},
    {menuid: 'STS_53', component: TimepunchComponent},
    {menuid: 'STS_110', component: TimepunchotreportComponent},
    {menuid: 'STS_111', component: TimepunchleavepostingComponent},
    {menuid: 'STS_112', component: MydocumentComponent},
    {menuid: 'STS_113', component: AuthorizegroupComponent},
    {menuid: 'STS_209', component: KanbanboardComponent},
    {menuid: 'STS_210', component: DevxkanbanboardComponent},
    {menuid: 'STS_114', component: HrgoalslistComponent},
    
    {menuid: 'STS_119', component: EffortauthconfigComponent},
    {menuid: 'STS_122', component: DashboardComponent},
    {menuid: 'STS_123', component: AspentaskbyreportComponent},
    {menuid: 'STS_124', component: AduitviewComponent}, 
    {menuid: 'STS_126', component: KanbanboardconfigComponent},
    {menuid: 'STS_127', component: ConsolidatetimepunchrptlistComponent}, 
    {menuid: 'STS_128', component: ShifttimepunchreportComponent}, 
    {menuid: 'STS_129', component: TickettreeviewComponent}, 
    {menuid: 'ERP_622', component: ApprovalformlistComponent},
    {menuid: 'STS_130', component: TicketprefixconfigComponent},
    {menuid: 'STS_131', component: TicketlinklistComponent},
    {menuid: 'ERP_223', component: PayslipComponent},
    {menuid: 'ERP_224', component: EmppayslippaymentComponent},
    {menuid: 'ERP_225', component: EmpsalaryhikeComponent},
    {menuid: 'ERP_229', component: PayslipreportComponent},
    {menuid: 'ERP_622', component: ApprovalformlistComponent},
    {menuid: 'ERP_237', component: EmpaccountsComponent},
    {menuid: 'ERP_252', component: PstemplatelistComponent},
    {menuid: 'ERP_219', component: EmpcategoryComponent},
    {menuid: 'STS_900', component: DevxgridComponent},
    {menuid: 'ERP_242', component: EosreportComponent},
    {menuid: 'ERP_352', component: LeavesalaryencashreportComponent},
    {menuid: 'STS_132', component: LicensecreatemainComponent},
    {menuid: 'STS_133', component: LicensereportComponent},

 ]
  
  constructor(public notificationService: NotificationService,) { }

  count:any;
  //JSON.parse(sessionStorage.getItem('tabcount'))
 // 
  getComponent(MenuID:string)
  {      
    this.count=JSON.parse(sessionStorage.getItem('tabcount')); 
    //console.log('beforeclick',this.count)   
    if(this.count <14)
    {
      var Component =this.menuIDMapping.filter(comp => comp.menuid === MenuID);         
      this.count=this.count+1;
     // console.log('afterclick',this.count)
      sessionStorage.setItem('tabcount',this.count)
     // console.log('Sessionaftrclik',JSON.parse(sessionStorage.getItem('tabcount')));
      return  Component[0].component
    }
    else
    {
      //console.log('ElseStatement')
      this.notificationService.warn('You have reached the maximum number of Tabs. Please close any other Tab(s) and try again.');    
    }

  }
}
