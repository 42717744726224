import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { Component,  OnInit, ViewChild} from '@angular/core';
import { Inject } from '@angular/core';
import {FormControl, FormBuilder,AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DxDataGridComponent,} from 'devextreme-angular';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith } from 'rxjs/operators';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import * as _moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DisableDataService } from '../disable-data.service';


export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

const moment = _moment;

export interface User {
  Name: string;
}

@Component({
  selector: 'app-timepunchreport',
  templateUrl: './timepunchreport.component.html',
  styleUrls: ['./timepunchreport.component.css']
})
export class TimepunchreportComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  disabledDates: Date[];
  employeelist: any;
  datasource: any;
  flag: any;
  LedgerID;
  cid:any;
  ledgerid1= 0;
 // isDateVisible: boolean;
 // Visiblelist:any;
  date= new Date();
  addflag= false;
  regex = '([01]?[0-9]|2[0-3]):[0-5][0-9]';
  temp:any;
  filteredOptions: Observable<string[]>;
  colorCtr: AbstractControl = new FormControl(null);
  myControl = new FormControl();
  empid:string[]=[];
  enabledate:boolean=true;
  myDateFilter:any;

  dateRange = [new Date(new Date().getFullYear(), new Date(). getMonth(), new Date().getDate()-1),
    new Date(new Date().getFullYear(), new Date(). getMonth(), new Date().getDate()+1)]
         
    //   myDateFilter= (d: Date): boolean => {
    //   return (d >= this.dateRange[0] && d <= this.dateRange[1])
    // }

  Status = {
    store: {
        type: 'array',
        data: [
            { id: 1, name: 'In' },
            { id: 0, name: 'Out' },
        ],
        key: 'id'
    }};

    displayedColumns = ['sno', 'Name', 'status', 'Action'];
    constructor(private formBuilder: FormBuilder,private datePipe: DatePipe,
      public service: HttpserviceService,
      public notificationService: NotificationService,
      private dialogService: DialogService,
      public dateservice:DisableDataService,
      public dialogRef: MatDialogRef<TimepunchreportComponent>,
      
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.employeelist = this.data.employeelist;
        this.empid= _.pluck(this.employeelist, 'Name');
        this.flag = this.data.flag;
        this.LedgerID = this.data.ledgerid;
        this.date = this.data.date;
        this.temp= this.data.Name;
        this.cid=this.data.CID;
        
       }

  ngOnInit(): void {

    this.myControl.setValue({Name: this.temp});
  
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | User>(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filter(name) : this.empid.slice())
      );

    if (this.flag == 'EDIT')
    {
     this.onvaluechanged();
    }
   
    //this.disabledDates=this.dateservice.getFederalHolidays()
  }

  private _filter(name: string): string[] {

    const filterValue = name.toLowerCase();   
      return this.empid.filter(option => option.toLowerCase().includes(filterValue));   
 
  }
  displayFn(empid?: User): string | undefined {
    return empid ? empid.Name : undefined;
  }
  selectedproduct(event) {    
    this.LedgerID=event.option.value;   
    this.myControl.setValue({Name: event.option.value }); 
    this.LedgerID=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));     
 
    if (this.LedgerID != 0)
    {
      this.addflag = true;
      this.gettimepunch();
    }
  }

  onCloseDialog()
  {
    // this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    // .afterClosed().subscribe(res => {
    //   if (res) {
    this.dialogRef.close({event: 'CLEAR'});
    //   }
    // });
  }
  onClose(model){
    this.dialogRef.close({event: this.flag, data: model});
  }
  logEvent(_flag, e)
  {
    //console.log('changedevent',_flag,e)
    let slno = 0;
    let  paramsExtra;
    slno = e.data.SlNo;
  

    if (_flag == 'RowInserting')
   {
    const time = (this.datePipe.transform( e.data.Date, 'dd-MM-yyyy') + ' ' + e.data.Time);
   //const time = (this.datePipe.transform(this.date, 'dd-MM-yyyy') + ' ' + e.data.Time);
    paramsExtra = {

      cid: this.cid,
      flag: 'ADD',
      Date: this.datePipe.transform(this.date, 'dd-MM-yyyy'),
      Time: time,
      TPid: '',
      Status: e.data.Status,
      ledgerid: this.LedgerID,
      Slno: 0,
      UserID: '',
      GroupID:sessionStorage.getItem('groupid')

    };

   }
  else if (_flag == 'RowRemoved')
  {
    //console.log('Delete')
   // const time = (this.datePipe.transform( e.data.Date, 'dd-MM-yyyy') + ' ' + e.data.Time);
    paramsExtra = {
      cid: this.cid,
      flag: 'DELETE',
     // Date: this.datePipe.transform(this.date, 'dd-MM-yyyy'),
      Date: this.date,
      Time: '',
      TPid: '',
      Status: e.data.Status,
      ledgerid: this.LedgerID,
      Slno: slno,
      UserID: '',
      GroupID: sessionStorage.getItem('groupid')

    };
  }
  
  this.service.httpPost('/timepunchreport/SetTimePuch',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
    
      if (users.errno === 0){
        this.notificationService.success('Submitted successfully');

      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });

  }
  onvaluechanged()
  { 
    if (this.LedgerID != 0)
    {
      this.addflag = true;
      this.gettimepunch();
    }

  }

  gettimepunch()
  {
    const params = {
      cid: this.cid,
      flag: 'default',
      ledgerid: this.LedgerID,
      date: this.datePipe.transform(this.date, 'dd-MM-yyyy')

        };
        const groupid = JSON.parse(sessionStorage.getItem('groupid'));

        const query = `cid=${params.cid}&flag=${params.flag}&ledgerid=${params.ledgerid}&date=${params.date}&groupid=${groupid}`;
        this.service.httpGet('/timepunchreport/GetTimePunch?',query)
        .subscribe(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            this.datasource = result.respdata.Table;
          
          //  console.log('RESULT',result.respdata)
            if(result.respdata.Table1.length==0)
            {              
                this.myDateFilter= (d: Date): boolean => {
                return (d >= this.dateRange[0] && d <= this.dateRange[1])                
                }
            }

           
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

}

