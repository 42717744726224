import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { MatDialog,MatDialogConfig,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import * as _ from 'underscore/underscore-min.js';
import { Platform } from '@ionic/angular';
import { attachmentComponent } from '../../Ticket/attachment/attachment.component';
import { TicketComponent } from '../../Ticket/ticket/ticket.component';
import { CommentComponent } from '../../Ticket/comment/comment.component';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { QuickaddComponent } from '../../Ticket/quickadd/quickadd.component';
import { LinkticketaschildComponent } from '../../Ticket/linkticketaschild/linkticketaschild.component';
import { TicketactivityviewComponent } from '../../Ticket/ticketactivityview/ticketactivityview.component';

@Component({
  selector: 'app-taskbyview',
  templateUrl: './taskbyview.component.html',
  styleUrls: ['./taskbyview.component.scss'],
})
export class TaskbyviewComponent implements OnInit {

  id: number = 10;
  row: number = this.id / 2;
  row1 = Array(this.row).fill(1);
  row2 = Array(3).fill(1).map((_, index) => index + 1);
  matrix = []

  CID:any;
  flag:any;
  Activityflag:any;
  TicketID:any='0';
  datasource:any;
  TaskTemplate:any;
  TicketActivity:any;
  companies:any
  Templatelist:any
  CurLedgerID:any
  selectedValue:any
  isEditable = false;
  TicketActivitylist:any;
  ticketlist:any
  ticketlist1:any
  ticketlist2:any
  ticketlist3:any
  ticketlist4:any
  ticketlist5:any
  ActiviteID:any
  menus1:any;
  TemplateGroupID:any;
  UserType:any;
  TemplateID:any=0;
  AddTemplate:any;
  addbtnvisible:boolean=false
  firstFormGroup:FormGroup;
  steppercount:number=0
  devicevisble:boolean=false
  mobileplatform:boolean=false
  showSpinner:any;
  menuListItems : MatMenuListItem[];
  TicketLink:any;
  tabgroups:any
  homepage1:any
  homepage2:any
  homepage3:any
  homepage4:any
  homepage5:any
  UserID:any;
  ChildIssues:any;
  linkedcount:any=0;
  childcount:any=0;
  TicketIDSubject:any;
  constructor(private dialog: MatDialog,public service: HttpserviceService,public dialogRef: MatDialogRef<TaskbyviewComponent>,
    public notificationService: NotificationService,
    private dialogService: DialogService,public plt: Platform, @Inject(MAT_DIALOG_DATA) public data: any) 
    { 

      this.TicketIDSubject=this.data.TicketID+' - '+this.data.Subject ;
      this.TicketID=this.data.TicketID;
     // console.log('Dialog data',this.data)
    this.flag=this.data.flag;

      var chunk_size = 2;
      var arr = this.row2;
      var groups = arr.map(function (e, i) {
        return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
      })
        .filter(function (e) { return e; });
      this.matrix = groups;
    
      this.firstFormGroup = new FormGroup({   
    
      Name: new FormControl('', [Validators.required]) 
      });  
    }

  ngOnInit() {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.UserID=curUser.userid;
    this.TemplateGroupID=curUser.TemplateGroupID;
  //  console.log("TemplateGroupID",this.TemplateGroupID)
    this.flag='View'
    this.ActiviteID='INT_VIEW'
    this. Loaddetails();
  }

  convertNewLines(comment: string): string {
    return comment.replace(/(\r\n|\n|\r)/g, '<br>');
  }

  
  Loaddetails()
  {
    this.showSpinner=true;
    let params = {
          cid:this.data.cid,
          flag:this.flag,
          TicketID:this.data.TicketID,
          TemplateID:this.data.TemplateID,
          UserName:this.UserID,
          TagID:this.data.TagID,
          ActiviteID:this.ActiviteID,
          TemplateGroupID:this.data.TemplateGroupID     
       }
       //console.log('VIEW CLICK',params)
          this.service.httpPost('/TicketCreator/GetTicketCreator?',params)
          .subscribe(data => {
            let result: any;
            result=data;
            this.showSpinner=true;
            setTimeout(() =>{
              this.showSpinner=false;
            },
            800)
            if (result.errno === 0){    
              //console.log('SUCCESS')                                    
              if(this.flag=='View' )
              {
               // console.log('SUCCESS1')  
             //  console.log("count",result.respdata.TicketListSub)
              var temp
              var panel2
              temp=_.countBy(result.respdata.TicketListSub, 'UIPanel');
             // console.log('temp',temp);
              panel2=_.where(temp,'2')
              //console.log("panel2", panel2);
              //console.log("panel2", panel2[1]);

              // if (this.plt.is('ios') || this.plt.is('android'))     
              // {
              //  // console.log("plat",1)
              //   this.devicevisble= true;
              //   this.mobileplatform=true;
              // }
             // else{
               // console.log("plat",2)
                this.devicevisble= panel2[1]>0 ? false : true;
              //   this.mobileplatform=false;
              // }

              // this.devicevisble= panel2[1]>0 ? false : true;
              // console.log("count", this.devicevisble);
             // console.log("TicketListSub",result.respdata.TicketListSub);
                this.ticketlist1=_.where(result.respdata.TicketListSub, {UIPanel: '1'});
                this.ticketlist2=_.where(result.respdata.TicketListSub, {UIPanel: '2'});
                this.ticketlist3=_.where(result.respdata.TicketListSub, {UIPanel: '3'});
                this.ticketlist4=_.where(result.respdata.TicketListSub, {UIPanel: '4'});
                this.ticketlist5=_.where(result.respdata.TicketListSub, {UIPanel: '5'});
 
              //  console.log('ticketlist1',this.ticketlist1);

                this.TicketActivitylist=result.respdata.TicketActivity;
                this.menuListItems=result.respdata.TaskTemplate
               // console.log('TASKBYVIEW',this.TicketActivitylist);
                let grouplist= result.respdata.Group;
                let grouplist1= _.where(result.respdata.Group, {UIPanel: 1});
                let grouplist2= _.where(result.respdata.Group, {UIPanel: 2});
                let grouplist3= _.where(result.respdata.Group, {UIPanel: 3});
                let grouplist4= _.where(result.respdata.Group, {UIPanel: 4});
                let grouplist5= _.where(result.respdata.Group, {UIPanel: 5});
               
                //----- panel1 ---//
                let newList1 = [];
                let temp1
                for (var i = 0; i < grouplist1.length; i++) 
                {
                  temp1={"name":grouplist1[i].UIGroup,"submenu": _.where( this.ticketlist1, {UIGroup: grouplist1[i].UIGroup})}
                  newList1.push(temp1);
                }
                this.ticketlist1=newList1;

                //----- panel2 ---//
                let newList2 = [];
                let temp2
                for (var i = 0; i < grouplist2.length; i++) 
                {
                  temp2={"name":grouplist2[i].UIGroup,"submenu": _.where( this.ticketlist2, {UIGroup: grouplist2[i].UIGroup})}
                  newList2.push(temp2);
                }
                this.ticketlist2=newList2;

                //----- panel3 ---//
                let newList3 = [];
                let temp3
                for (var i = 0; i < grouplist3.length; i++) 
                {
                  temp3={"name":grouplist3[i].UIGroup,"submenu": _.where( this.ticketlist3, {UIGroup: grouplist3[i].UIGroup})}
                  newList3.push(temp3);
                }
                this.ticketlist3=newList3;

                //----- panel4 ---//
                let newList4 = [];
                let temp4
                for (var i = 0; i < grouplist4.length; i++) 
                {
                  temp4={"name":grouplist4[i].UIGroup,"submenu": _.where( this.ticketlist4, {UIGroup: grouplist4[i].UIGroup})}
                  newList4.push(temp4);
                }
                this.ticketlist4=newList4;

                //----- panel5 ---//
                let newList5 = [];
                let temp5
                for (var i = 0; i < grouplist5.length; i++) 
                {
                  temp5={"name":grouplist5[i].UIGroup,"submenu": _.where( this.ticketlist5, {UIGroup: grouplist5[i].UIGroup})}
                  newList5.push(temp5);
                }
                this.ticketlist5=newList5;
        
                this.TicketLink=result.respdata.TicketLink;
                this.ChildIssues=result.respdata.ChildIssues;

                this.linkedcount=result.respdata.TicketLink.length;
                this.childcount=result.respdata.ChildIssues.length;
              }         
                           
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  clickMenuItem(menuItem : MatMenuListItem,Value){
   
    this.TicketID=this.data.TicketID

    // this.ActiviteID=this.data.ActiviteID
    // let actvityflag=this.data.ActiviteID.split("_",4)
    this.ActiviteID=menuItem.ActivityID
    let actvityflag=menuItem.ActivityID.split("_",4)
     
   
   // console.log('Value',this.ActiviteID[1]);
    this.flag='ACTIVITY'
    if(actvityflag[1]=='VIEW')
    {
      //console.log('VIEwmode')
      this.flag='View'
      this.firstFormGroup.controls.Name.setValue('test');  
      this.isEditable=true
      //this.steppercount=1
      //stepper.next();
    }
    else if (actvityflag[1]=='ATTACHMENT')
    {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        flag: 'EDIT',
        pid:0,
        
       template:this.TaskTemplate,
       TicketID:this.TicketID
 
      }
      const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
      dialogRef.afterClosed()
    }

    else if (actvityflag[1]=='COMMENT')
    {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        flag: 'EDIT',
        pid:0,
        
       template:this.TaskTemplate,
       TicketID:this.TicketID,
       TemplateID:this.TemplateID
 
      }
      const dialogRef = this.dialog.open(CommentComponent, dialogConfig)
      dialogRef.afterClosed()
    }
    
    // if (actvityflag[1] !=='ATTACHMENT')
    // {
    //   this.Activityflag='EDIT'
    //   this.Loaddetails1();
    // }
    
    if ( actvityflag[1] !='COMMENT' && actvityflag[1] !='ATTACHMENT')
    {
    //  console.log('Comment');
      this.Activityflag='EDIT'
      this.Loaddetails1();
    }

  }

  addLink()
  {
    //this.Activityflag='EDIT'
    //console.log('HI')
    this.flag='ACTIVITY'
    this.Activityflag='EDIT'
    this.ActiviteID='INT_LINK'
    this.Loaddetails1();
  }
  
RemoveLink(event: MouseEvent, buttonText: string,ticketno: string)
{
  this.dialogService.openConfirmDialog('Are you sure do you want remove this ticket link?'+"  "+ ticketno)
  .afterClosed().subscribe(res => {
   if (res) {
  const query = `cid=${this.CID}&slno=${buttonText}&userID=${this.UserID}`;
  this.service.httpGet('/TicketCreator/SetRemoveLink?',query)
  .subscribe(
    data => {
      let result: any;
      result = data;
      if (result.errno === 0) {     
        this.notificationService.success(result.errdesc);  
        this.Loaddetails();        
        
      }
      else {       
        this.notificationService.success(result.errdesc); //':: Problem details');        
      }
    },
    error => {
      console.log(error);
    
      });
    }
    });
}
  Loaddetails1()
  {
    this.showSpinner=true;
    let params = {
                  cid:this.data.cid,
                  flag:'ACTIVITY',
                  TicketID:this.data.TicketID,
                  TemplateID:this.data.TemplateID,
                  UserName:this.UserID,
                  TagID:this.data.TagID,
                  ActiviteID:this.ActiviteID,
                  TemplateGroupID:this.data.TemplateGroupID     
                }
        //console.log('VIEW CLICK',params)
                this.service.httpPost('/TicketCreator/GetTicketCreator?',params)
                .subscribe(data => {
                  let result: any;
                  result=data;
                  this.showSpinner=true;
                  setTimeout(() =>{
                    this.showSpinner=false;
                  },
                  800)
                  if (result.errno === 0){    
                    //console.log('SUCCESS')                                    
                    if(this.flag=='View')
                    {
                           // console.log('bye')
                    }                       
                    else 
                    {
                      //console.log('HI',result.respdata.TaskTemplate)
                      this.TaskTemplate=result.respdata.TaskTemplate
                      const dialogConfig = new MatDialogConfig();
                      dialogConfig.disableClose = true;
                      dialogConfig.autoFocus =  true;
                      dialogConfig.data = {
                        flag: this.Activityflag,
                        pid:0,
                        height: '350px',
                        width: '60%',
                        template:this.TaskTemplate,
                        TicketID:this.TicketID,
                        ActiviteID:this.ActiviteID,
                        TagID: 'INTERNAL',
                        TemplateGroupID:this.data.TemplateGroupID,
                        TemplateID:this.data.TemplateID           
                      }
                     // console.log('TASKBYVIEWAssignee',dialogConfig.data)
                      const dialogRef =  this.dialog.open(TicketComponent, dialogConfig)      
                      dialogRef.afterClosed().subscribe(result => {                      
                             this.flag='View';
                             this.ActiviteID="INT_VIEW"
                             this.Loaddetails();                                                                                                                         
                        });          
                    }
                  }
                  else{
                    this.notificationService.success(result.errdesc); //':: Problem details');
                  }
                }, 
                (error: AppError) => {
                  if (error instanceof BadInput)           
                    console.log(error);
                  else throw error;       
                });
  }

  onCloseDialog()
  {    
    this.dialogRef.close({event: 'CLEAR'}); 
  }

  ChildQuickAdd()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.width = '500px'; // Set the width
    dialogConfig.height = '200px';
    dialogConfig.data = {
     flag: 'QuickAdd',    
     cid:this.CID,           
     TicketID:this.TicketID
    }
    const dialogRef = this.dialog.open(LinkticketaschildComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
      this.Loaddetails();     
    });
  }

  QuickAdd()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
     flag: 'QuickAdd',
     cid:this.CID,           
     TicketID:this.TicketID,
     TicketIDSubject:this.TicketIDSubject
    }
    const dialogRef = this.dialog.open(QuickaddComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
      this.Loaddetails();     
    });
    
  }


RemoveChildissues(event: MouseEvent,ticketno: string)
{
  this.dialogService.openConfirmDialog('Are you sure do you want remove this ticket link?'+"  "+ ticketno)
  .afterClosed().subscribe(res => {
   if (res) {
    let params = {     
      CID:this.CID,
      Flag:"REMOVELINK",    
      TicketID:ticketno              
    };
    this.service.httpPost('/TicketCreator/SetQuickAdd',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 1) {
          this.notificationService.success(result.errdesc);          
          this.Loaddetails();        
         
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
          
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
    });
}

activeview(value: any) {

  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {

    dataview: value,


  }
  const dialogRef = this.dialog.open(TicketactivityviewComponent, dialogConfig)
  dialogRef.afterClosed()

}

}

export class MatMenuListItem {
  ActivityID: string;
  LabelName1: string;
  MenuIcon: string;

}
export interface GroupALL {
  TicketID: string;
  Subject: string;  
}
export type Menu = {
  name: string, 
  submenu: { name: string}[]
}
