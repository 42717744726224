import { TicketactivityviewComponent } from './../ticketactivityview/ticketactivityview.component';
import { json } from '@angular-devkit/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TicketComponent } from '../ticket/ticket.component';
import { MatStepper } from '@angular/material/stepper';
import * as _ from 'underscore/underscore-min.js';
import { attachmentComponent } from '../attachment/attachment.component';
import { Platform } from '@ionic/angular';
import { CommentComponent } from '../comment/comment.component';
import { AppSidebarComponent } from './../../../../LandingPageComponents/sidebar/sidebar.component'
import { environment } from 'src/environments/environment';
import { TaskbyviewComponent } from '../../CustomsReport/taskbyview/taskbyview.component';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { EmiteventserviceService } from 'src/app/Services/OtherServices/emiteventservice.service';
import { QuickaddComponent } from '../quickadd/quickadd.component';
import { LinkticketaschildComponent } from '../linkticketaschild/linkticketaschild.component';
import { Console } from 'console';


@Component({
  selector: 'app-itsuserhomepage',
  templateUrl: './itsuserhomepage.component.html',
  styleUrls: ['./itsuserhomepage.component.scss'],
})
export class ItsuserhomepageComponent implements OnInit {

  @ViewChild(AppSidebarComponent) AppSidebarComponent;

  id: number = 10;
  row: number = this.id / 2;
  row1 = Array(this.row).fill(1);
  row2 = Array(3).fill(1).map((_, index) => index + 1);
  matrix = []
  CIDisvisible: boolean = false;
  CID: any;
  flag: any;
  Activityflag: any;
  TicketID: any = '0';
  datasource: any;
  TaskTemplate: any;
  TicketActivity: any;
  companies: any
  Templatelist: any
  CurLedgerID: any
  selectedValue: any
  isEditable = false;
  TicketActivitylist: any;
  ticketlist: any
  ticketlist1: any
  ticketlist2: any
  ticketlist3: any
  ticketlist4: any
  ticketlist5: any
  ActiviteID: any
  menus1: any;
  TemplateGroupID: any;
  UserType: any;
  TemplateID: any = 0;
  AddTemplate: any;
  addbtnvisible: boolean = false
  firstFormGroup: FormGroup;
  steppercount: number = 0
  devicevisble: boolean = false
  mobileplatform: boolean = false
  showSpinner: any;
  menuListItems: MatMenuListItem[];

  tabgroups: any
  homepage1: any
  homepage2: any
  homepage3: any
  homepage4: any
  homepage5: any
  UserID: any;
  linkscount: any = 0;
  childcount: any = 0;
  data1: any;
  count:any;
  TicketLink: any;
  ChildIssues: any;
  geovisible:boolean;
  emitData:any='';
  @ViewChild('stepper') stepper;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns = ['CreatedDate',  'TicketID', 'Subject','Type', 'Priority', 'Status', 'Action'];
  constructor(private dialog: MatDialog, public service: HttpserviceService, private dialogService: DialogService, private eventService: EmiteventserviceService,
    public notificationService: NotificationService, public plt: Platform,private cdr: ChangeDetectorRef) {

    var chunk_size = 2;
    var arr = this.row2;
    var groups = arr.map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
    })
      .filter(function (e) { return e; });
    this.matrix = groups;

    this.firstFormGroup = new FormGroup({

      Name: new FormControl('', [Validators.required])
    });

  }

  ngAfterViewInit() {
   
    // Now you can use this.stepper safely
    if (this.stepper) {
      this.stepper.next();
      console.error('done');
      this.cdr.detectChanges();
    } else {
      console.error('MatStepper is not initialized.');
    }
  }

  ngOnInit() {


    if (sessionStorage.getItem('ManinformrowData')!=='' && sessionStorage.getItem('ManinformrowData')!==null)
    {
      this.emitData=JSON.parse(sessionStorage.getItem('ManinformrowData'))
    }
    

    this.CID = JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID = curUser.ledgerid;
    this.UserID = curUser.userid;
    this.TemplateGroupID = curUser.TemplateGroupID;
    if (curUser.UserType === 'External') {
      this.CIDisvisible = false;
    }
    else {
      this.CIDisvisible = true;
    }
    
  // console.log("TicketID", sessionStorage.getItem('TicketID'))

    //if (JSON.parse(sessionStorage.getItem('TicketID')) !== null){
      if (sessionStorage.getItem('TicketID') !== null && sessionStorage.getItem('TicketID') !== 'null')
      {     
       // console.log('hi')
    
        // this.count=JSON.parse(sessionStorage.getItem('tabcount'))
    
        //   sessionStorage.setItem('tabcount',this.count)

            this.CID = 101
            let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
            this.CurLedgerID = curUser.ledgerid;
            this.flag = 'View'
            this.ActiviteID = 'INT_VIEW'
            this.Activityflag = 'EDIT'
            this.TemplateGroupID = 1;
            this.TemplateID = 1;
            this.TicketID = sessionStorage.getItem('TicketID')

            this.firstFormGroup.controls.Name.setValue('test');
            this.isEditable = true
            this.Loaddetails();                            
      }
      else if (this.emitData.otherForm !==null && this.emitData.otherForm==true && this.emitData.flag=='View')
      {

            this.CID = 101
            let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
            this.CurLedgerID = curUser.ledgerid;
            this.flag = this.emitData.flag
            this.ActiviteID ='INT_VIEW'
            this.Activityflag = 'EDIT'
            this.TemplateGroupID = 1;
            this.TemplateID = 1;
            this.TicketID = this.emitData.rowValue.TicketID

            this.firstFormGroup.controls.Name.setValue('test');
            this.isEditable = true
            this.Loaddetails();  
            
            sessionStorage.setItem('ManinformrowData','')

      }
      else if (this.emitData.otherForm !==null && this.emitData.otherForm==true && this.emitData.flag=='Edit')
        {
  
              this.CID = 101
              let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
              this.CurLedgerID = curUser.ledgerid;
              this.flag =  'ACTIVITY'
              this.ActiviteID ='INT_EDIT'
              this.Activityflag = 'EDIT'
              this.TemplateGroupID = 1;
              this.TemplateID = 1;
              this.TicketID = this.emitData.rowValue.TicketID
  
              this.firstFormGroup.controls.Name.setValue('test');
              this.isEditable = false
              this.Loaddetails();  
              
              sessionStorage.setItem('ManinformrowData','')
  
        }
      else 
      {     
        this.flag = 'PAGELOAD'
        this.ActiviteID = 'EDIT'
        this.Loaddetails();     
       
      }
    sessionStorage.setItem('TicketID', null)
    

  }
  activeview(value: any) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {

      dataview: value,


    }
    const dialogRef = this.dialog.open(TicketactivityviewComponent, dialogConfig)
    dialogRef.afterClosed()

  }
  onCompanySelect(event) {
    this.CID = event;
    this.Loaddetails();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  onTemplateSelect(event) {
    this.TemplateID = event;
    this.flag = 'HomePage'
    this.Loaddetails();
  }

  convertNewLines(comment: string): string {
    return comment.replace(/(\r\n|\n|\r)/g, '<br>');
  }

  
  
  clickMenuItem(menuItem: MatMenuListItem, Value, stepper: MatStepper) {

    this.TicketID = Value

    this.ActiviteID = menuItem.ActivityID
    const actvityflag = menuItem.ActivityID.split("_", 4)

    this.flag = 'ACTIVITY'
    if (actvityflag[1] == 'VIEW') {  
      this.flag = 'View'
      this.firstFormGroup.controls.Name.setValue('test');
      this.isEditable = true
      this.steppercount = 1
      stepper.next();
    }
    else if (actvityflag[1] == 'ATTACHMENT') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        flag: 'EDIT',
        pid: 0,

        template: this.TaskTemplate,
        TicketID: this.TicketID,
        geovisible:this.geovisible

      }
      const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
      dialogRef.afterClosed()
    }
    else if (actvityflag[1] == 'COMMENT') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        flag: 'EDIT',
        pid: 0,

        template: this.TaskTemplate,
        TicketID: this.TicketID,
        TemplateID: this.TemplateID

      }
      const dialogRef = this.dialog.open(CommentComponent, dialogConfig)
      dialogRef.afterClosed()
    }
    //var temp=actvityflag[1] ;
    //console.log('actvityflag[1].toString()',actvityflag[1].toString())
    // if (actvityflag[1].toString() !=='ATTACHMENT' || actvityflag[1].toString()!=='COMMENT')
    // {
    //   this.Activityflag='EDIT'
    //   this.Loaddetails();
    // }

    // if (actvityflag[1] !=='COMMENT')
    // {
    //   this.Activityflag='EDIT'
    //   this.Loaddetails();
    // }

    // console.log('amjath', actvityflag);

    if (actvityflag[1] != 'COMMENT' && actvityflag[1] != 'ATTACHMENT') {
      //  console.log('Comment');
      this.Activityflag = 'EDIT'
      this.Loaddetails();
    }
    // else if (actvityflag[1] !='ATTACHMENT')
    // {
    //   console.log('ATTACHMENT');
    //   this.Activityflag='EDIT'
    //   this.Loaddetails();
    // }    
  }
  addLink() {
    //this.Activityflag='EDIT'
    this.flag = 'ACTIVITY'
    this.ActiviteID = 'INT_LINK'
    this.Loaddetails();

  }

  baseURL: any;
  viewissue() {
    this.baseURL = environment.TicketapiUrl;
    // console.log('baseurl',this.baseURL)
    //window.open("http://localhost:55121/Controllers/Admin/WebForm1.aspx?issueid=" + 1001 );
    window.open(this.baseURL + "/Controllers/Admin/WebForm1.aspx?issueid=" + 1001);
  }

  RemoveLink(event: MouseEvent, buttonText: string, ticketno: string) {
    this.dialogService.openConfirmDialog('Are you sure do you want remove this ticket link?' + "  " + ticketno)
      .afterClosed().subscribe(res => {
        if (res) {
          const query = `cid=${this.CID}&slno=${buttonText}&userID=${this.UserID}`;
          this.service.httpGet('/TicketCreator/SetRemoveLink?', query)
            .subscribe(
              data => {
                let result: any;
                result = data;
                if (result.errno === 0) {
                  this.notificationService.success(result.errdesc);
                  this.Loaddetails();
                  this.stepper.selectedIndex = 1;
                }
                else {
                  this.notificationService.success(result.errdesc); //':: Problem details');        
                }
              },
              error => {
                console.log(error);

              });
        }
      });
  }

  ViewLink(event: MouseEvent, buttonText: string) {
    sessionStorage.setItem('TicketID', buttonText);
    let item: any = { "name": "Ticket Manager", "type": "FORM", "icon": "arrow_right", "menuid": "STS_203", "parentid": "54", "visible": true };
    this.eventService.emitMenuSelect(item);
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus =  true;       
    // dialogConfig.data = {                 
    //         pid:0,
    //         height: '350px',
    //         width: '70%',                            
    //         cid:this.CID,
    //         flag:"View",
    //         TicketID:buttonText,
    //         TemplateID:1,
    //         UserName:this.UserID,
    //         LoginFlag:'External',
    //         ActiviteID:"INT_VIEW",
    //         TemplateGroupID:1                   
    //     }                   
    //     const dialogRef =  this.dialog.open(TaskbyviewComponent, dialogConfig)
    //     // dialogRef.afterClosed().subscribe(result => {     
    //     //   this.Submit();     
    //     // });
    // // Your logic here
  }

  Viewchildticket(event: MouseEvent, buttonText: string) {
    sessionStorage.setItem('TicketID', buttonText);
    let item: any = { "name": "Ticket Manager", "type": "FORM", "icon": "arrow_right", "menuid": "STS_203", "parentid": "54", "visible": true };
    this.eventService.emitMenuSelect(item);
    
  }

  Loaddetails() {
    this.showSpinner = true;
    let params = {
      cid: this.CID,
      flag: this.flag,
      TicketID: this.TicketID,
      TemplateID: this.TemplateID,
      UserName: this.UserID,
      TagID: 'External',
      ActiviteID: this.ActiviteID,
      TemplateGroupID: this.TemplateGroupID

    }
    //console.log('vigi',params)

    this.service.httpPost('/TicketCreator/GetTicketCreator?', params)
      .subscribe(data => {
        let result: any;
        result = data;
        this.showSpinner = true;
        setTimeout(() => {
          this.showSpinner = false;
        },
          800)
        if (result.errno === 0) {

          if (this.flag == 'PAGELOAD') {
            this.companies = result.respdata.Companylist
            this.Templatelist = result.respdata.TaskTemplate

            // console.log('temp',this.Templatelist)
            this.TemplateID = this.Templatelist[0].TemplateID;
            this.onTemplateSelect(this.TemplateID);

            this.geovisible=result.respdata.FormPropertySettings==undefined? false: JSON.parse( result.respdata.FormPropertySettings[0].TagValue)
             // console.log('temp', result.respdata.FormPropertySettings)

          }
          else if (this.flag == 'LOADGRID') {
           
            this.menuListItems = result.respdata.TaskTemplate
            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.TicketList);
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
            this.AddTemplate = result.respdata.AddTemplate

            this.addbtnvisible = this.AddTemplate.length > 0 ? true : false

          }
          else if (this.flag == 'HomePage') {

           // console.log('this.stepper.selectedIndex',this.stepper.selectedIndex)
           if(this.stepper.selectedIndex == 0)
           {
            // console.log('hi')
             this.menuListItems = result.respdata.TaskTemplate
           }
            // this.datasource = new MatTableDataSource<GroupALL>(result.respdata.TicketList);
            // this.datasource.sort = this.sort;
            // this.datasource.paginator = this.paginator;
            this.AddTemplate = result.respdata.AddTemplate
            this.tabgroups = result.respdata.HomePage
            //  console.log('HomePage',result.respdata.HomePage )
            for (var i = 0; i < result.respdata.HomePage.length; i++) {
              if (i == 0) {
                this.homepage1 = result.respdata.Table4
                // this.homepage1 = new MatTableDataSource<GroupALL>(result.respdata.Table4);
                // this.homepage1.sort = this.sort;
                // this.homepage1.paginator = this.paginator;
              }
              else if (i == 1) {
                this.homepage2 = result.respdata.Table5
                // this.homepage2 = new MatTableDataSource<GroupALL>(result.respdata.Table5);
                // this.homepage2.sort = this.sort;
                // this.homepage2.paginator = this.paginator;
              }
              else if (i == 2) {
                this.homepage3 = result.respdata.Table6
              }
              else if (i == 3) {
                this.homepage4 = result.respdata.Table7
              }
              else if (i == 3) {
                this.homepage5 = result.respdata.Table8
              }


            }
            // _.where(result.respdata.TicketListSub, {UIPanel: 1});
            this.addbtnvisible = this.AddTemplate.length > 0 ? true : false

          }
          else if (this.flag == 'View') {
              //console.log("count",result.respdata.TicketListSub)
            var temp
            var panel2
            temp = _.countBy(result.respdata.TicketListSub, 'UIPanel');
           // console.log('temp',temp);
            panel2 = _.where(temp, '2')
           //  console.log("panel2", panel2);

            // if (this.plt.is('ios') || this.plt.is('android')) {
            //   //   console.log("plat",1)
            //   this.devicevisble = true;
            //   this.mobileplatform = true;
            // }
            // else {
              //  console.log("plat",2)
              this.devicevisble = panel2[1] > 0 ? false : true;
              //this.mobileplatform = false;
            //}

            // this.devicevisble= panel2[1]>0 ? false : true;
            // console.log("TicketListSub",result.respdata.TicketListSub);
            this.ticketlist1 = _.where(result.respdata.TicketListSub, { UIPanel: '1' });
            this.ticketlist2 = _.where(result.respdata.TicketListSub, { UIPanel: '2' });
            this.ticketlist3 = _.where(result.respdata.TicketListSub, { UIPanel: '3' });
            this.ticketlist4 = _.where(result.respdata.TicketListSub, { UIPanel: '4' });
            this.ticketlist5 = _.where(result.respdata.TicketListSub, { UIPanel: '5' });

          //  console.log('ticketlist1',this.ticketlist1);

            this.TicketActivitylist = result.respdata.TicketActivity;
            this.menuListItems = result.respdata.TaskTemplate

            let grouplist = result.respdata.Group;
            let grouplist1 = _.where(result.respdata.Group, { UIPanel: 1 });
            let grouplist2 = _.where(result.respdata.Group, { UIPanel: 2 });
            let grouplist3 = _.where(result.respdata.Group, { UIPanel: 3 });
            let grouplist4 = _.where(result.respdata.Group, { UIPanel: 4 });
            let grouplist5 = _.where(result.respdata.Group, { UIPanel: 5 });

            //----- panel1 ---//
            let newList1 = [];
            let temp1
            for (var i = 0; i < grouplist1.length; i++) {
              temp1 = { "name": grouplist1[i].UIGroup, "submenu": _.where(this.ticketlist1, { UIGroup: grouplist1[i].UIGroup }) }
              newList1.push(temp1);
            }
            this.ticketlist1 = newList1;
           // console.log("grouplist1", newList1);
            //----- panel2 ---//
            let newList2 = [];
            let temp2
            for (var i = 0; i < grouplist2.length; i++) {
              temp2 = { "name": grouplist2[i].UIGroup, "submenu": _.where(this.ticketlist2, { UIGroup: grouplist2[i].UIGroup }) }
              newList2.push(temp2);
            }
            this.ticketlist2 = newList2;

            //----- panel3 ---//
            let newList3 = [];
            let temp3
            for (var i = 0; i < grouplist3.length; i++) {
              temp3 = { "name": grouplist3[i].UIGroup, "submenu": _.where(this.ticketlist3, { UIGroup: grouplist3[i].UIGroup }) }
              newList3.push(temp3);
            }
            this.ticketlist3 = newList3;

            //----- panel4 ---//
            let newList4 = [];
            let temp4
            for (var i = 0; i < grouplist4.length; i++) {
              temp4 = { "name": grouplist4[i].UIGroup, "submenu": _.where(this.ticketlist4, { UIGroup: grouplist4[i].UIGroup }) }
              newList4.push(temp4);
            }
            this.ticketlist4 = newList4;

            //----- panel5 ---//
            let newList5 = [];
            let temp5
            for (var i = 0; i < grouplist5.length; i++) {
              temp5 = { "name": grouplist5[i].UIGroup, "submenu": _.where(this.ticketlist5, { UIGroup: grouplist5[i].UIGroup }) }
              newList5.push(temp5);
            }
            this.ticketlist5 = newList5;

            this.TicketLink = result.respdata.TicketLink;
            this.ChildIssues = result.respdata.ChildIssues;

            this.linkscount = result.respdata.TicketLink.length;
            this.childcount = result.respdata.ChildIssues.length;

          }
          else {
            this.TaskTemplate = result.respdata.TaskTemplate
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
              flag: this.Activityflag,
              pid: 0,
              height: '350px',
              width: '60%',
              template: this.TaskTemplate,
              TicketID: this.TicketID,
              ActiviteID: this.ActiviteID,
              TagID: 'INTERNAL',
              TemplateGroupID: this.TemplateGroupID,
              TemplateID: this.TemplateID

            }

            const dialogRef = this.dialog.open(TicketComponent, dialogConfig)
            dialogRef.afterClosed().subscribe(result => {
              this.flag = 'HomePage'
              if (this.steppercount == 0 || this.steppercount == 1) {
                if (this.steppercount == 0) {
                  this.Loaddetails();
                  //console.log('hi')
                  this.stepper.selectedIndex = 1;
                }
                else if (this.steppercount == 1) {
                  this.flag = 'View';
                  this.ActiviteID = "INT_VIEW"
                  this.Loaddetails();
                 // console.log('bye')

                  this.stepper.selectedIndex = 1;
                }

              }


            });

          }




        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log(error);
          else throw error;
        });
  }

  addRow() {
    // console.log("TaskTemplate",this.AddTemplate[0].ActivityID)
    this.ActiviteID = this.AddTemplate[0].ActivityID
    this.flag = 'ADD'
    this.Activityflag = 'ADD'
    this.TicketID = '0'
    this.Loaddetails();


  }
  createView() {
    this.isEditable = false
   // console.log("reset")
    this.flag = 'HomePage'
    this.ActiviteID = 'EDIT'
    this.Loaddetails();
    this.steppercount = 0
    this.firstFormGroup.controls.Name.setValue('');
  }
  onAttachment(menuItem: MatMenuListItem, Value) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      flag: 'EDIT',
      pid: 0,
      height: '350px',
      width: '60%',
      template: this.TaskTemplate,
      TicketID: this.TicketID,
      geovisible:this.geovisible

    }
    const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
    dialogRef.afterClosed()
  }

  Refresh() {
    this.TemplateID = this.TemplateID;
    //  console.log('fdsgds',this.TemplateID);
    this.flag = 'HomePage'
    this.Loaddetails();
  }

  ChildQuickAdd()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.width = '500px'; // Set the width
    dialogConfig.height = '200px';
    dialogConfig.data = {
     flag: 'QuickAdd',    
     cid:this.CID,           
     TicketID:this.TicketID
    }
    const dialogRef = this.dialog.open(LinkticketaschildComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
      this.Loaddetails();     
    });
  }
  
  QuickAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      flag: 'QuickAdd',
      cid: this.CID,
      TicketID: this.TicketID
    }
    const dialogRef = this.dialog.open(QuickaddComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.Loaddetails();
    });

  }

  RemoveChildissues(event: MouseEvent, ticketno: string) {
    this.dialogService.openConfirmDialog('Are you sure do you want remove this ticket from parent?' + "  " + ticketno)
      .afterClosed().subscribe(res => {
        if (res) {
          let params = {
            CID: this.CID,
            Flag: "REMOVELINK",
            TicketID: ticketno
          };
          this.service.httpPost('/TicketCreator/SetQuickAdd', params)
            .subscribe(
              (data) => {
                let result: any;
                result = data;
                if (result.errno === 1) {
                  this.notificationService.success(result.errdesc);
                  this.Loaddetails();
                  this.stepper.selectedIndex = 1;
                }
                else {
                  this.notificationService.success(result.errdesc); //':: Problem details');

                }
              },
              (error: AppError) => {
                if (error instanceof BadInput)
                  console.log(error);
                else throw error;
              });
        }
      });
  }

  ArrowBack() {
    this.createView();
    this.stepper.selectedIndex = 0;
  }

}
export class MatMenuListItem {
  ActivityID: string;
  LabelName1: string;
  MenuIcon: string;

}
export interface GroupALL {
  TicketID: string;
  Subject: string;
}
export type Menu = {
  name: string,
  submenu: { name: string }[]
}
