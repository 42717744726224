import { TicketactivityviewComponent } from './../../components/ITS/Ticket/ticketactivityview/ticketactivityview.component';

import { WebcameraComponent } from './../../components/ERP/Sales/webcamera/webcamera.component';
import { TicketComponent } from './../../components/ITS/Ticket/ticket/ticket.component';
import { TicketlistComponent } from './../../components/ITS/Ticket/ticketlist/ticketlist.component';
import { DatewisereportComponent } from './../../components/ETS/DateWiseReport/datewisereport/datewisereport.component';
import { MerchantlistComponent } from './../../components/WorkFlow/Client/merchantlist/merchantlist.component';
import { StatusComponent } from './../../components/WorkFlow/Status/status/status.component';
import { TimezoneComponent } from './../../components/WorkFlow/TimeZone/timezone/timezone.component';
import { TimezonelistComponent } from './../../components/WorkFlow/TimeZone/timezonelist/timezonelist.component';
import { BranchlistComponent } from './../../components/WorkFlow/Branch/branchlist/branchlist.component';
import { TypeofissueComponent } from './../../components/WorkFlow/TypeOfIssue/typeofissue/typeofissue.component';
import { QuestionlistComponent } from './../../components/WorkFlow/Question/questionlist/questionlist.component';
import { QuestionComponent } from './../../components/WorkFlow/Question/question/question.component';
import { CurrentyearcarryforwardComponent } from './../../components/HR/HRPolicy/currentyearcarryforward/currentyearcarryforward.component';
import { ProfileComponent } from './../../components/Admin/Profile/profile/profile.component';
import { EmailconfigurationComponent } from './../../components/WorkFlow/EmailConfig/emailconfiguration/emailconfiguration.component';
import { ProductconfigComponent } from './../../components/WorkFlow/Product/productconfig/productconfig.component';
import { ProductconfiglistComponent } from './../../components/WorkFlow/Product/productconfiglist/productconfiglist.component';
import { HrconfigurationComponent } from './../../components/WorkFlow/HRConfig/hrconfiguration/hrconfiguration.component';
import { EntityComponent } from './../../components/WorkFlow/Entity/entity/entity.component';
import { LeavetypemasterviewComponent } from './../../components/WorkFlow/LeaveTypeMaster/leavetypemasterview/leavetypemasterview.component';
import { PopupnotificationComponent } from './../../components/Employee/Popup/popupnotification/popupnotification.component';

import { EffortapprovalComponent } from './../../components/Project/EffortApproval/effortapproval.component';

import { DocumentdownloadComponent } from './../../components/Employee/Documents/documentdownload/documentdownload.component';
import { DocumentcontrollistComponent } from './../../components/Employee/Documents/documentcontrollist/documentcontrollist.component';
import { DocumentcontrolComponent } from './../../components/Employee/Documents/documentcontrol/documentcontrol.component';
import { AlternateempComponent } from './../../components/Project/alternateemp/alternateemp.component';
import { ProjectallocationbyweekListComponent } from './../../components/Project/ProjectAllocation/projectallocationbyweek-list/projectallocationbyweek-list.component';
import { EmpRegListComponent } from './../../components/HR/EmployeeRegistration/emp-reg-list/emp-reg-list.component';
import { ProjecteffortComponent } from './../../components/Project/projecteffort/projecteffort.component';
import { HierarchyexcelimportComponent } from './../../components/Project/EmpHierarchy/hierarchyexcelimport/hierarchyexcelimport.component';
import { EmployeeHierarchyComponent } from './../../components/Project/EmpHierarchy/employee-hierarchy/employee-hierarchy.component';
import { EmployeeHierarchyListComponent } from './../../components/Project/EmpHierarchy/employee-hierarchy-list/employee-hierarchy-list.component';
import { ProjectconfigurationListComponent } from './../../components/WorkFlow/ProjectMaster/projectconfiguration-list/projectconfiguration-list.component';
import { ProjectconfigurationComponent } from './../../components/WorkFlow/ProjectMaster/projectconfiguration/projectconfiguration.component';
import { GroupListComponent } from './../../components/Admin/group/group-list/group-list.component';
import { GroupComponent } from './../../components/Admin/group/group/group.component';
import { UserComponent } from './../../components/Admin/users/user/user.component';
import { UserListComponent } from './../../components/Admin/users/user-list/user-list.component';
import { EmpleaverequestComponent } from './../../components/HR/LeaveApproval/empleaverequest/empleaverequest.component';
import { LeaverequestComponent } from './../../components/HR/LeaveApproval/leaverequest/leaverequest.component';
import { LeaveApprovalComponent } from './../../components/HR/LeaveApproval/leave-approval/leave-approval.component';
import { TicketreportComponent } from './../../components/ETS/ticketreport/ticketreport.component';
import { MonthlyreportComponent } from './../../components/ETS/monthlyreport/monthlyreport.component';
import { LedgerstatementComponent } from './../../components/Employee/LedgerStatement/ledgerstatement.component';
import { AdvancerequestviewComponent } from './../../components/Employee/AdvanceRequest/advancerequestview/advancerequestview.component';
import { AdvancerequestComponent } from './../../components/Employee/AdvanceRequest/advancerequest/advancerequest.component';
import { EmpleaverptComponent } from './../../components/HR/EmpLeaveReport/empleaverpt.component';
import { TimesheetviewComponent } from './../../components/HR/TimeSheet/timesheetview/timesheetview.component';
import { TimesheetComponent } from './../../components/HR/TimeSheet/timesheet/timesheet.component';
import { HrpoliciesComponent } from './../../components/HR/HRPolicy/hrpolicies/hrpolicies.component';
import { HrpoliciesviewComponent } from './../../components/HR/HRPolicy/hrpoliciesview/hrpoliciesview.component';
import { TrainingComponent } from './../../components/HR/Training/training/training.component';
import { TrainingacessComponent } from './../../components/HR/Training/trainingacess/trainingacess.component';
import { GoalsComponent } from './../../components/HR/Goals/goals/goals.component';
import { GoalaccessComponent } from './../../components/HR/Goals/goalaccess/goalaccess.component';
import { AttendancereportComponent } from './../../components/HR/AttendanceReport/attendancereport/attendancereport.component';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlsModule } from '../controls/controls.module';
import { EmpAttReportViewComponent } from './../../components/Employee/EmpAttReportview/emp-att-report-view/emp-att-report-view.component';
import { TimepunchreportlistComponent } from './../../components/HR/timepuchreport/timepunchreportlist/timepunchreportlist.component';
import { TimepunchreportComponent } from './../../components/HR/timepuchreport/timepunchreport/timepunchreport.component';
import { ExpensepostingComponent } from './../../components/Employee/Expenseposting/expenseposting/expenseposting.component';
import { ExpensepostinglistComponent } from './../../components/Employee/Expenseposting/expensepostinglist/expensepostinglist.component';
import { DialogColorcodeComponent } from './../../components/HR/AttendanceReport/dialog-colorcode/dialog-colorcode.component';
import { GoalslistComponent } from './../../components/HR/Goals/goalslist/goalslist.component';
import { TraininglistComponent } from './../../components/HR/Training/traininglist/traininglist.component';
import { CustomsreportComponent } from './../../components/ETS/CustomsReport/customsreport.component';
import { DialogleaveapprovalComponent } from './../../components/HR/LeaveApproval/dialogleaveapproval/dialogleaveapproval.component';
import { EmpRegComponent } from './../../components/HR/EmployeeRegistration/emp-reg/emp-reg.component';
import { ProjectallocationbyweekComponent } from './../../components/Project/ProjectAllocation/projectallocationbyweek/projectallocationbyweek.component';
import { LeavetypemasterComponent } from './../../components/WorkFlow/LeaveTypeMaster/leavetypemaster/leavetypemaster.component';
import { ChangePasswordComponent } from './../../components/Admin/change-password/change-password.component';
import { LeaveManagementComponent } from './../../components/HR/LeaveManagement/leave-management/leave-management.component';
import{ EntityconfigComponent } from './../../components/WorkFlow/Entity/entityconfig/entityconfig.component';
import { EstimationweeklyreportComponent } from './../../components/Project/estimationweeklyreport/estimationweeklyreport.component';
import { AuthMasterComponent } from './../../components/HR/auth-master/auth-master.component';
import { AuthMasterListComponent } from './../../components/HR/auth-master/auth-master-list/auth-master-list.component';
import { TypeofissuelistComponent } from './../../components/WorkFlow/TypeOfIssue/typeofissuelist/typeofissuelist.component';
import { BranchComponent } from './../../components/WorkFlow/Branch/branch/branch.component';
import { ItempricelistComponent } from './../../components/ERP/Inventory/inventory/itempricelist/itempricelist.component';
import { NeworderlistComponent } from './../../components/ERP/Sales/neworderlist/neworderlist.component';
import { NeworderComponent } from './../../components/ERP/Sales/neworder/neworder.component';
import { AssementgroupComponent } from './../../components/WorkFlow/AssementGroup/assementgroup/assementgroup.component';
import { StatuslistComponent } from './../../components/WorkFlow/Status/statuslist/statuslist.component';
import { CategorylistComponent } from './../../components/WorkFlow/Category/categorylist/categorylist.component';
import { CategoryComponent } from './../../components/WorkFlow/Category/category/category.component';
import { LeavegrouplistComponent } from './../../components/WorkFlow/LeaveGroup/leavegrouplist/leavegrouplist.component';
import { LeavegroupComponent } from './../../components/WorkFlow/LeaveGroup/leavegroup/leavegroup.component';
import { MerchantComponent } from './../../components/WorkFlow/Client/merchant/merchant.component';
import { ConsolidateEffortComponent } from './../../components/ETS/Consolidatereport/consolidate-effort/consolidate-effort.component';
import { EffortauthorizeComponent } from './../../components/ETS/EffortAuthorize/effortauthorize/effortauthorize.component';
import { EmpprojectlistComponent } from './../../components/Project/EmpHierarchy/empprojectlist/empprojectlist.component';
import { attachmentComponent } from './../../components/ITS/Ticket/attachment/attachment.component';
import { AttendanceComponent } from './../../components/Project/attendance/attendance.component';
import { TaskbyComponent } from './../../components/ITS/CustomsReport/taskby/taskby.component';
import { ITSHomepageComponent } from './../../components/ITS/itshomepage/itshomepage.component';
import { ProjectAllocationByDayComponent } from './../../components/Project/project-allocation-by-day/project-allocation-by-day.component';
import { ResourceallocationbydayComponent } from './../../components/Project/resourceallocationbyday/resourceallocationbyday.component';
import { EmpTimesheetComponent } from './../../components/ETS/EmpTimesheet/emp-timesheet/emp-timesheet.component';
import { SampleComponent } from './../../components/ETS/Sample/sample/sample.component';
import { ItsuserhomepageComponent } from './../../components/ITS/Ticket/itsuserhomepage/itsuserhomepage.component';
import { EmpattendanceviewComponent } from './../../components/Employee/AttendanceReport/empattendanceview/empattendanceview.component';
import { CustomizeditemrptComponent } from './../../components/ERP/customizeditemrpt/customizeditemrpt.component';
import { UploadprofilepicComponent } from './../../components/HR/EmployeeRegistration/uploadprofilepic/uploadprofilepic.component';
import { DocumentreqlistComponent } from '../../components/Employee/DocumentRequest/documentreqlist/documentreqlist.component';
import { DocumentreqviewComponent } from '../../components/Employee/DocumentRequest/documentreqview/documentreqview.component';
import { UploaddocrequestComponent } from '../../components/Employee/DocumentRequest/uploaddocrequest/uploaddocrequest.component';
import { DownloaddocrequestComponent } from '../../components/Employee/DocumentRequest/downloaddocrequest/downloaddocrequest.component';
import { EmployeeOnBoardlistComponent } from 'src/app/components/Employee/EmployeeOnBoard/employee-on-boardlist/employee-on-boardlist.component';
import { EmployeeOnBoardComponent } from 'src/app/components/Employee/EmployeeOnBoard/employee-on-board/employee-on-board.component';
import { OnboardapprovalComponent } from 'src/app/components/Employee/EmployeeOnBoard/onboardapproval/onboardapproval.component';
import { EmpdocreqlistComponent } from 'src/app/components/Employee/DocumentRequest/empdocreqlist/empdocreqlist.component';
import { TimesheetticketComponent } from 'src/app/components/ETS/EmpTimesheet/timesheetticket/timesheetticket.component';
import { TaskbyviewComponent } from 'src/app/components/ITS/CustomsReport/taskbyview/taskbyview.component';
import { CommentComponent } from 'src/app/components/ITS/Ticket/comment/comment.component';
import { DatecontrolComponent } from './../../components/HR/DateControl/datecontrol/datecontrol.component';
import { ProjectcostComponent } from './../../components/WorkFlow/ProjectCost/projectcost/projectcost.component';
import { EffortcostreportComponent } from './../../components/ETS/EffortCostReport/effortcostreport/effortcostreport.component';
import { HelpdesktaskbyComponent } from './../../components/ITS/Helpdesk/helpdesktaskby/helpdesktaskby.component';
import { Goals2Component } from './../../components/HR/Goals/goals2/goals2.component';
import { TimepunchComponent } from './../../components/HR/Timepunch/timepunch/timepunch.component';
import { TimepunchotreportComponent } from './../../components/HR/timepuchreport/timepunchotreportlist/timepunchotreport/timepunchotreport.component';
import { TimepunchleavepostingComponent } from 'src/app/components/HR/Timepunchleaveposting/timepunchleaveposting/timepunchleaveposting.component';
import { DialogtimepunchleavepostingComponent } from './../../components/HR/LeaveApproval/dialogtimepunchleaveposting/dialogtimepunchleaveposting.component';
import { EditotComponent } from './../../components/HR/timepuchreport/editot/editot.component';
import { UpdatefingerprintComponent } from './../../components/HR/timepuchreport/updatefingerprint/updatefingerprint.component';
import { MydocumentComponent } from '../../components/Employee/Documents/mydocument/mydocument.component';
import { AuthorizegroupComponent } from './../../components/HR/authorizegroup/authorizegroup.component';
import { KanbanboardComponent } from 'src/app/components/ITS/kanbanboard/kanbanboard.component';
import { NewleavedirectapprovalComponent } from 'src/app/components/HR/LeaveApproval/newleavedirectapproval/newleavedirectapproval.component';
import { NewpopupleavedirectapprovalComponent } from 'src/app/components/HR/LeaveApproval/newpopupleavedirectapproval/newpopupleavedirectapproval.component';
import { NewleaverequestComponent } from 'src/app/components/HR/LeaveApproval/newleaverequest/newleaverequest.component';
import { HrgoalslistComponent } from 'src/app/components/HR/Goals/hrgoalslist/hrgoalslist.component';
import { LeavetypeconfigurationComponent } from 'src/app/components/HR/leavetypeconfiguration/leavetypeconfiguration.component';
import { NestedgridComponent } from 'src/app/components/WorkFlow/nestedgrid/nestedgrid.component';
import { TicketemailconfigexcelimportComponent } from 'src/app/components/WorkFlow/ticketemailconfigexcelimport/ticketemailconfigexcelimport.component';
import { ExcelImportDialogComponent } from 'src/app/components/WorkFlow/ticketemailconfigexcelimport/excel-import-dialog/excel-import-dialog.component';
import { EditEmailTagListComponent } from 'src/app/components/WorkFlow/ticketemailconfigexcelimport/edit-email-tag-list/edit-email-tag-list.component';
import { TicketemailrulecreationComponent } from 'src/app/components/WorkFlow/ticketemailrulecreation/ticketemailrulecreation.component';
import { EffortauthconfigComponent } from 'src/app/components/WorkFlow/EffortAuthorizer/effortauthconfig/effortauthconfig.component';
import { ResourceallocationbyweekComponent } from 'src/app/components/Project/resourceallocationbyweek/resourceallocationbyweek.component';
import { CrmdatecontrolComponent } from 'src/app/components/HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';
import { ResourceallocationbyweeklistComponent } from 'src/app/components/Project/resourceallocationbyweeklist/resourceallocationbyweeklist.component';
import { LeavemgtelogComponent } from 'src/app/components/HR/LeaveApproval/leavemgtelog/leavemgtelog.component';
import { AspentaskbyreportComponent } from 'src/app/components/ITS/CustomsReport/aspentaskbyreport/aspentaskbyreport.component';
import { AduitviewComponent } from './../../components/ERP/auditview/aduitview.component';
import { KanbanboardfilterComponent } from 'src/app/components/ITS/kanbanboardfilter/kanbanboardfilter.component';
import { KanbanuserinitialComponent } from 'src/app/components/ITS/kanbanuserinitial/kanbanuserinitial.component';
import { KanbanboardconfigComponent } from 'src/app/components/ITS/kanbanboardconfig/kanbanboardconfig.component';
import { KanbanboardconfigpopupComponent } from 'src/app/components/ITS/kanbanboardconfigpopup/kanbanboardconfigpopup.component';
import { KanbankeyvaluejsondataviewComponent } from 'src/app/components/ITS/kanbankeyvaluejsondataview/kanbankeyvaluejsondataview.component';
import { ConsolidatetimepunchrptlistComponent } from 'src/app/components/HR/timepuchreport/consolidatetimepunchrptlist/consolidatetimepunchrptlist.component';
import { ShifttimepunchreportComponent } from 'src/app/components/HR/ShiftManagement/shifttimepunchreport/shifttimepunchreport.component';
import { QuickaddComponent } from './../../../app/components/ITS/Ticket/quickadd/quickadd.component';
import { TickettreeviewComponent } from 'src/app/components/ITS/tickettreeview/tickettreeview.component';
import { LinkticketaschildComponent } from './../../../app/components/ITS/Ticket/linkticketaschild/linkticketaschild.component';
import { ApprovalformlistComponent } from 'src/app/components/ERP/ApprovalForm/approvalformlist/approvalformlist.component';
import { ApprovalformfilterComponent } from 'src/app/components/ERP/ApprovalForm/approvalformfilter/approvalformfilter.component';
import { ApprovalformPostingComponent } from 'src/app/components/ERP/ApprovalForm/approvalform-posting/approvalform-posting.component';
import { DependencyrptComponent } from 'src/app/components/ITS/dependencyrpt/dependencyrpt.component';
import { TicketprefixconfigComponent } from 'src/app/components/WorkFlow/ticketprefixconfig/ticketprefixconfig.component';
import { TicketprefixdialogComponent } from 'src/app/components/WorkFlow/ticketprefixdialog/ticketprefixdialog.component';
import { TicketlinklistComponent } from 'src/app/components/WorkFlow/TicketLink/ticketlinklist/ticketlinklist.component';
import { TicketlinkconfigComponent } from 'src/app/components/WorkFlow/TicketLink/ticketlinkconfig/ticketlinkconfig.component';
import { MultiselectwithsearchComponent } from 'src/app/components/Control/multiselectwithsearch/multiselectwithsearch.component';
import { MatSelectSearchComponent } from 'src/app/components/Control/mat-select-search/mat-select-search.component';
import { PayslipComponent } from 'src/app/components/HR/payslip/payslip.component';
import { VoucherforHRComponent } from 'src/app/components/HR/voucherfor-hr/voucherfor-hr.component';
import { PayslipreportComponent } from 'src/app/components/HR/Payslip Report/payslipreport/payslipreport.component';
import { EmppayslippaymentComponent } from 'src/app/components/HR/EmpPayslipPayment/emppayslippayment/emppayslippayment.component';
import { PayslippaymentfilterComponent } from 'src/app/components/HR/EmpPayslipPayment/payslippaymentfilter/payslippaymentfilter.component';
import { EmpsalaryhikeComponent } from 'src/app/components/HR/SalaryHike/empsalaryhike/empsalaryhike.component';
import { DynamicTableComponentComponent } from 'src/app/components/HR/dynamicTable/dynamic-table-component/dynamic-table-component.component';
import { EmpsalaryhikepostingComponent } from 'src/app/components/HR/SalaryHike/empsalaryhikeposting/empsalaryhikeposting.component';
import { EmpaccountsComponent } from 'src/app/components/HR/EmpAccounts/empaccounts/empaccounts.component';
import { PstemplateComponent } from 'src/app/components/HR/PSTemplate/pstemplate/pstemplate.component';
import { PstemplatelistComponent } from 'src/app/components/HR/PSTemplate/pstemplatelist/pstemplatelist.component';
import { EmpcategoryComponent } from 'src/app/components/HR/EmpCategory/empcategory/empcategory.component';
import { KanbanboardsearchComponent } from 'src/app/components/ITS/kanbanboardsearch/kanbanboardsearch.component';
import { WorklocationComponent } from 'src/app/components/Admin/users/worklocation/worklocation.component';
import { DevxgridComponent } from 'src/app/components/Admin/devxgrid/devxgrid.component';
import { DialogformainformComponent } from 'src/app/components/Admin/dialogformainform/dialogformainform.component';
import { UserandgrouppermissionComponent } from 'src/app/components/Admin/userandgrouppermission/userandgrouppermission.component';
import { EosreportComponent } from 'src/app/components/HR/EOS/eosreport/eosreport.component';
import { EospostingComponent } from 'src/app/components/HR/EOS/eosposting/eosposting.component';
import { LeavesalaryencashpostingComponent } from 'src/app/components/HR/LeaveSalary_EnCash/leavesalaryencashposting/leavesalaryencashposting.component';
import { LeavesalaryencashreportComponent } from 'src/app/components/HR/LeaveSalary_EnCash/leavesalaryencashreport/leavesalaryencashreport.component';
import { LicensecreatemainComponent } from 'src/app/components/Admin/License/licensecreatemain/licensecreatemain.component';
import { LicensecreatesubComponent } from 'src/app/components/Admin/License/licensecreatesub/licensecreatesub.component';
import { LicensereportComponent } from 'src/app/components/Admin/License/licensereport/licensereport.component';



@NgModule({
  declarations: [
    NewleavedirectapprovalComponent,
    NewpopupleavedirectapprovalComponent,
    NewleaverequestComponent,
    KanbanboardComponent,
    EmpAttReportViewComponent,
    TimepunchreportComponent,
    TimepunchreportlistComponent,
    ExpensepostinglistComponent,
    ExpensepostingComponent,
    AttendancereportComponent,
    DialogColorcodeComponent,
    GoalaccessComponent,
    GoalsComponent,
    GoalslistComponent,
    TrainingacessComponent,
    TrainingComponent,
    TraininglistComponent,
    HrpoliciesComponent,
    HrpoliciesviewComponent,
    TimesheetComponent,
    TimesheetviewComponent,
    EmpleaverptComponent,
    AdvancerequestComponent,
    AdvancerequestviewComponent,
    LedgerstatementComponent,
    CustomsreportComponent,
    MonthlyreportComponent,
    TicketreportComponent,
    LeaveApprovalComponent,
    LeaverequestComponent,
    EmpleaverequestComponent,
    DialogleaveapprovalComponent,
    UserListComponent,
    UserComponent,
    GroupComponent,
    GroupListComponent,
    ProjectconfigurationComponent,
    ProjectconfigurationListComponent,
    EmployeeHierarchyListComponent,
    EmployeeHierarchyComponent,
    HierarchyexcelimportComponent,
    ProjecteffortComponent,
    EmpRegListComponent,
    EmpRegComponent,
    ProjectallocationbyweekListComponent,
    ProjectallocationbyweekComponent,
    AlternateempComponent,
    DocumentcontrolComponent,
    DocumentcontrollistComponent,
    DocumentdownloadComponent,
    EffortapprovalComponent,
    PopupnotificationComponent,
    LeavetypemasterviewComponent,
    LeavetypemasterComponent,
     ChangePasswordComponent,
     LeaveManagementComponent,
     EntityconfigComponent,
     EntityComponent,
     HrconfigurationComponent,
     ProductconfiglistComponent,
     ProductconfigComponent,
     EstimationweeklyreportComponent,
     EmailconfigurationComponent,
     ProfileComponent,
     CurrentyearcarryforwardComponent,
     QuestionComponent,
     QuestionlistComponent,
     AuthMasterComponent,
     AuthMasterListComponent,
     TypeofissuelistComponent,
     TypeofissueComponent,
     BranchlistComponent,
     BranchComponent,
     TimezonelistComponent,
     TimezoneComponent,
     ItempricelistComponent,
     NeworderlistComponent,
     NeworderComponent,
     AssementgroupComponent,
     StatusComponent,
     StatuslistComponent,
     CategorylistComponent,
     CategoryComponent,
     LeavegrouplistComponent,
     LeavegroupComponent,
     MerchantlistComponent,
     MerchantComponent,
     DatewisereportComponent,
     ConsolidateEffortComponent,
     EffortauthorizeComponent,
     EmpprojectlistComponent,
     TicketlistComponent,
     TicketComponent,
     attachmentComponent,
    WebcameraComponent,
    AttendanceComponent,
    TaskbyComponent,
    ITSHomepageComponent,
    ProjectAllocationByDayComponent,
    ResourceallocationbydayComponent,
    EmpTimesheetComponent,
    SampleComponent,
    ItsuserhomepageComponent,
    EmpattendanceviewComponent,
    CustomizeditemrptComponent,
    UploadprofilepicComponent,
    DocumentreqlistComponent,
    DocumentreqviewComponent,
    UploaddocrequestComponent,
    DownloaddocrequestComponent,
    EmployeeOnBoardlistComponent,
    EmployeeOnBoardComponent,
    OnboardapprovalComponent,
    EmpdocreqlistComponent,
    TimesheetticketComponent,
    TaskbyviewComponent,
    CommentComponent,
    DatecontrolComponent,
    TicketactivityviewComponent,
    ProjectcostComponent,
    EffortcostreportComponent,
    HelpdesktaskbyComponent,
    Goals2Component,
    TimepunchComponent,
    TimepunchotreportComponent,
    TimepunchleavepostingComponent,
    DialogtimepunchleavepostingComponent,
    EditotComponent,
    UpdatefingerprintComponent,
    MydocumentComponent,
    AuthorizegroupComponent,
    HrgoalslistComponent,
    LeavetypeconfigurationComponent,
    NestedgridComponent,
    TicketemailconfigexcelimportComponent,
    ExcelImportDialogComponent,
    EditEmailTagListComponent,
    TicketemailrulecreationComponent,
    EffortauthconfigComponent,
    ResourceallocationbyweekComponent,
    CrmdatecontrolComponent,
    ResourceallocationbyweeklistComponent,
    LeavemgtelogComponent,
    AspentaskbyreportComponent,
    AduitviewComponent,
    KanbanboardfilterComponent,
    KanbanuserinitialComponent,
    KanbankeyvaluejsondataviewComponent,
    KanbanboardconfigComponent,
    KanbanboardconfigpopupComponent,
    ConsolidatetimepunchrptlistComponent,
    ShifttimepunchreportComponent,
    QuickaddComponent,
    TickettreeviewComponent,
    LinkticketaschildComponent,
    ApprovalformlistComponent,
    ApprovalformfilterComponent,
    ApprovalformPostingComponent,
    DependencyrptComponent,
    TicketprefixconfigComponent,
    TicketprefixdialogComponent,
    TicketlinklistComponent,
    TicketlinkconfigComponent,
    MultiselectwithsearchComponent ,
    MatSelectSearchComponent,
    PayslipComponent,
    VoucherforHRComponent,
    PayslipreportComponent,
    EmppayslippaymentComponent,
    PayslippaymentfilterComponent,
    EmpsalaryhikeComponent,
    DynamicTableComponentComponent,
    EmpsalaryhikepostingComponent,
    ApprovalformlistComponent,
    ApprovalformfilterComponent,
    ApprovalformPostingComponent,
    DependencyrptComponent,
    TicketprefixconfigComponent,
    TicketprefixdialogComponent,
    TicketlinklistComponent,
    TicketlinkconfigComponent,
    EmpaccountsComponent,
    PstemplateComponent,
    PstemplatelistComponent,
    EmpcategoryComponent,
    KanbanboardsearchComponent,
    WorklocationComponent,
    DevxgridComponent,
    DialogformainformComponent,
    UserandgrouppermissionComponent,
    EosreportComponent,
    EospostingComponent,
    LeavesalaryencashpostingComponent,
    LeavesalaryencashreportComponent,
    LicensecreatemainComponent,
    LicensecreatesubComponent,
    LicensereportComponent

  ],
  imports: [
    CommonModule,ControlsModule
  ],
  exports:[
    MatSelectSearchComponent
    // TabsComponent,
    // AppHeaderComponent,
    // AppSidebarComponent,
    // MenuListItemComponent,
    // FullComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class ComponentsModule { }
